<template>
  <div>
    <b-row>
      <b-col md="6" lg="4" class="d-md-flex">
        <div class="m-auto">
          <b-card body-class="text-center">
            <b-img fluid :src="badgeUri" :class="qrCodeClasses" />
          </b-card>
        </div>
        <div class="d-md-none mt-3">
          <b-input-group class="input--alt-style form-inputs--lg" v-if="showSection">
            <b-form-input type="text" disabled :value="serialCode"></b-form-input>
            <input-group-append classes="p-0">
              <template v-slot:content>
                <b-button
                  variant="info"
                  @click="openExternal"
                  class="me-1"
                >
                  <font-awesome-icon icon="external-link-alt"/>
                </b-button>
                <b-button
                  variant="info"
                  class="me-1"
                  v-clipboard="badgeUriToShare"
                  @success="handleSuccess"
                  @error="handleError"
                  v-b-tooltip.hover
                  title="Copia link"
                >
                  <font-awesome-icon icon="copy"/>
                </b-button>
                <b-button
                  variant="info"
                  class="me-1"
                  @click="openModalShare"
                  v-b-tooltip.hover
                  title="Condividi"
                >
                  <font-awesome-icon icon="share-alt"/>
                </b-button>
                <b-button
                  variant="info"
                  @click="openConfig"
                  v-b-tooltip.hover
                  title="Configurazione"
                >
                  <font-awesome-icon icon="cogs"/>
                </b-button>
              </template>
            </input-group-append>
          </b-input-group>
        </div>
      </b-col>
      <b-col md="6" lg="4" class="mt-3 mt-md-0">
        <b-card
          class="h-100"
          body-class="d-flex align-items-center justify-content-center"
        >
          <template #header>
            <card-header
              title="Rating"
            />
          </template>
          <div>
            <h1 :class="totalPointHighlightClasses">
              {{totalPoint}}
            </h1>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" class="d-none d-md-block d-lg-none">
        <b-row class="d-none d-md-flex d-lg-none">
          <b-col md="6" class="mt-3">
            <b-input-group class="input--alt-style form-inputs--lg" v-if="showSection">
              <b-form-input type="text" disabled :value="serialCode"></b-form-input>
              <b-input-group-append class="ms-2">
                <b-button
                  variant="info"
                  class="me-1"
                  @click="openExternal"
                  v-b-tooltip.hover
                  title="Apri in un'altra finestra"
                >
                  <font-awesome-icon icon="external-link-alt"/>
                </b-button>
                <b-button
                  variant="info"
                  class="me-1"
                  v-clipboard="badgeUriToShare"
                  @success="handleSuccess"
                  @error="handleError"
                  v-b-tooltip.hover
                  title="Copia link"
                >
                  <font-awesome-icon icon="copy"/>
                </b-button>
                <b-button
                  variant="info"
                  class="me-1"
                  @click="openModalShare"
                  v-b-tooltip.hover
                  title="Condividi"
                >
                  <font-awesome-icon icon="share-alt"/>
                </b-button>
                <b-button
                  variant="info"
                  size="xs"
                  @click="openConfig"
                  v-b-tooltip.hover
                  title="Configurazione"
                >
                  <font-awesome-icon icon="cogs"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            v-if="isOperative"
            md="6"
            class="mt-3"
          >
            <b-button
              variant="warning"
              class="w-100"
              @click="openModalFeedback"
            >
              RICHIEDI FEEDBACK
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" class="mt-3 mt-lg-0">
        <b-card
          class="h-100"
          body-class="d-flex align-items-center justify-content-center"
        >
          <template #header>
            <card-header
              title="Statistiche rapide"
            />
          </template>
          <div class="my-auto w-100 px-md-3">
            <div class="mb-3">
              <h6 class="mb-1">Richieste di feedback senza risposta</h6>
              <b-progress
                :value="countTotalWait"
                :max="countTotalWaitMax"
                show-value
                variant="primary"
                :class="openFeedbackClasses"
                :data-value="countTotal"
              />
            </div>
            <div class="mb-3">
              <h6 class="mb-1">Feedback ricevuti</h6>
              <b-progress
                :value="countTotal"
                :max="countMax"
                show-value
                variant="info"
                :class="receivedClasses"
                :data-value="countTotal"
              />
            </div>
            <div>
              <h6 class="mb-1">Feedback effettuati</h6>
              <b-progress
                :value="feedbackCountTotal"
                :max="feedbackCountMax"
                show-value
                variant="warning"
                :class="doneClasses"
                :data-value="feedbackCountTotal"
              />
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-none d-lg-flex">
      <b-col md="4" class="mt-3">
        <b-input-group class="input--alt-style form-inputs--lg" v-if="showSection">
          <b-form-input type="text" disabled :value="serialCode"></b-form-input>
          <b-input-group-append class="ms-2">
            <b-button
              variant="warning"
              @click="openExternal"
              v-b-tooltip.hover
              title="Apri in un'altra finestra"
            >
              <font-awesome-icon icon="external-link-alt"/>
            </b-button>
            <b-button
              variant="warning"
              v-clipboard="badgeUriToShare"
              @success="handleSuccess"
              @error="handleError"
              v-b-tooltip.hover
              title="Copia link"
            >
              <font-awesome-icon icon="copy"/>
            </b-button>
            <b-button
              variant="warning"
              @click="openModalShare"
              v-b-tooltip.hover
              title="Condividi"
            >
              <font-awesome-icon icon="share-alt"/>
            </b-button>
            <b-button
              variant="warning"
              size="xs"
              @click="openConfig"
              v-b-tooltip.hover
              title="Configurazione"
            >
              <font-awesome-icon icon="cogs"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col
        v-if="isOperative"
        md="4"
        class="mt-3"
      >
        <b-button
          variant="warning"
          class="w-100"
          @click="openModalFeedback"
        >
          RICHIEDI FEEDBACK
        </b-button>
      </b-col>
    </b-row>
    <modal-feedback id="userFeedbackQR" :on-modal-hide="reload"/>
    <modal-badge-setting id="badgeConfig" />
    <modal-badge-share id="badgeShare" :uri="badgeUriToShare" />
  </div>
</template>

<script>
import { formatDateOnly } from '@/utils/formatterHelper';
import { toastInformation } from '@/utils/toast';
import { createBadgeCompleteUrl, createBadgeUri } from '@/utils/utilities';
import InputGroupAppend from '../helpers/InputGroupAppend.vue';
import { isNotEmpty } from '../../utils/validators';

const ModalFeedback = () => import('@/components/ModalFeedback.vue');
const ModalBadgeSetting = () => import('@/components/userSections/ModalBadgeSetting.vue');
const ModalBadgeShare = () => import('@/components/userSections/ModalBadgeShare.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'QrcodeBadgeFeedback',
  components: {
    InputGroupAppend, ModalBadgeSetting, ModalFeedback, ModalBadgeShare, CardHeader,
  },
  data() {
    return {
      headingScoring: 'STATUS & SCORING',
      headingStats: 'STATISTICHE',
    };
  },
  props: {
    serialCode: String,
    locked: Boolean,
    qrOnly: Boolean,
    totalPoint: Number,
    countTotalWait: Number,
    countTotalWaitMax: Number,
    countTotal: Number,
    countMax: Number,
    feedbackCountTotal: Number,
    feedbackCountMax: Number,
    serialCodeCreation: String,
    reloadData: Function,
  },
  computed: {
    heading() {
      return `IL TUO ID: ${this.serialCode}`;
    },
    badgeUri() {
      return createBadgeUri(this.serialCode);
    },
    badgeUriToShare() {
      return createBadgeCompleteUrl(this.serialCode);
    },
    serialCodeCreationDay() {
      return formatDateOnly(this.serialCodeCreation);
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    qrCodeClasses() {
      if (!this.isOperative) return 'has-filter--blur';
      return '';
    },
    showSection() {
      return !this.qrOnly && this.isOperative;
    },
    totalPointHighlightClasses() {
      const baseClasses = 'm-auto point-highlight point-highlight--wallet';
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.totalPoint.toString().length;
        if (a >= 3 && a < 5) {
          return `${baseClasses} point-highlight--100`;
        }
        if (a >= 5 && a < 8) {
          return `${baseClasses} point-highlight--1000`;
        }
        if (a >= 8) {
          return `${baseClasses} point-highlight--10000`;
        }
      }
      return baseClasses;
    },
    openFeedbackClasses() {
      if ((this.countTotal / this.countMax) * 100 < 10) {
        return 'progress--lg progress--value-xs';
      }
      return 'progress--lg';
    },
    receivedClasses() {
      if ((this.countTotal / this.countMax) * 100 < 10) {
        return 'progress--lg progress--value-xs';
      }
      return 'progress--lg';
    },
    doneClasses() {
      if ((this.feedbackCountTotal / this.feedbackCountMax) * 100 < 10) {
        return 'progress--lg progress--value-xs';
      }
      return 'progress--lg';
    },
  },
  methods: {
    openModalFeedback() {
      this.$bvModal.show('userFeedbackQR');
    },
    openConfig() {
      console.log('openConfig');
      this.$bvModal.show('badgeConfig');
    },
    handleSuccess(e) {
      console.log('copy data', e);
      toastInformation('Link badge copiato nella clipboard');
    },
    handleError(e) {
      console.log('copy data error', e);
    },
    openExternal() {
      // console.log('external', this.badgeUriToShare);
      window.open(this.badgeUriToShare, '_blank');
    },
    reload() {
      console.log('qrbadge feedback reload');
      if (this.reloadData) this.reloadData();
    },
    openModalShare() {
      this.$bvModal.show('badgeShare');
    },
  },
};
</script>

<style scoped>

</style>
