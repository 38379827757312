<template>
  <b-input-group-append :class="classesComputed">
    <slot name="content"></slot>
  </b-input-group-append>
</template>

<script>

import { isNotEmpty } from '../../utils/validators';

export default {
  name: 'InputGroupAppend',
  props: {
    classes: String,
  },
  computed: {
    classesComputed() {
      // console.log('InputGroupAppend classes', this.classes);
      const baseClasses = 'input-group-text';
      const check = isNotEmpty(this.classes);
      return check ? `${baseClasses} ${this.classes}` : baseClasses;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
